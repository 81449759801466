import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const VisitDateModal = ({ open, onClose, data }) => {
  if (!data) {
    // If no data is available, show the message indicating no visit dates
    return (
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "50%",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            No Visit Dates Available
          </Typography>
          <Typography variant="body1">
            There are no visit dates available for this site.
          </Typography>
        </Box>
      </Modal>
    );
  }

  if (Array.isArray(data) && data.length === 1) {
    // If only one visit date is available, show that date
    return (
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "50%",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            Visit Date
          </Typography>
          <Typography variant="body1">
            {new Date(data[0]?.dateOfVisit).toLocaleDateString()}
          </Typography>
        </Box>
      </Modal>
    );
  }

  // If more than one visit date is available, show them in a list
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: "60%",
          maxHeight: "60%",
          overflow: "auto",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
          Visit Dates
        </Typography>
        <Divider sx={{ my: 2, borderColor: 'rgba(0, 0, 0, 0.8)' }} />
        <Box sx={{ mt: 2 }}>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {data?.map((visit, index) => (
              <li key={index}>
                <Typography variant="subtitle1">
                  {new Date(visit.dateOfVisit).toLocaleDateString()}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Box>
    </Modal>
  );
};

export default VisitDateModal;
